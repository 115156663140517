.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--color-secondary);
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: var(--header-height);
}

.logo img {
  height: 50px;   
}


.logoNative {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente o conteúdo da logo */
  align-items: center; /* Centraliza verticalmente o conteúdo da logo */
  width: 100%;
  height: 100%; /* Garante que a logo preenche o espaço */
  padding-top:10px;
}

.logoNative img {
  height: 30px;
}


.nav-menu {
  display: none; /* Mantido oculto por padrão para comportamento mobile */
  flex-direction: column; /* Colunas para mobile */
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: var(--color-secondary);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 15px;
  z-index: 1000;
  gap: 0px; /* Gap entre os botões */
  flex: 1; /* Mantém a flexibilidade do menu */
}

.nav-menu.open {
  display: flex;
}

.nav-menu button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;  
  width: 100%;
}


.nav-menu button:hover {
  background-color:var(--color-secondary-hover);
  
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  #gap: 20px;
  #margin-bottom: 20px;
  #padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}


.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #61dafb;
}

.hamburger-menu {
  display: block;
  color: gold;
  font-size: 30px;
  cursor: pointer;
}
