/* .section {
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
} */

.contact-list {
  list-style: none;
  padding: 0;
}

.contact-list li {
  margin: 15px 0;
}

.contact-list a {
  color: var(--button-blue);
  text-decoration: none;
}

.contact-list a:hover {
  text-decoration: underline;
}
