/* .section {
  padding: 100px 20px;
} */

#about {
  background-color: var(--color-light);
  text-align: center;
}

.about-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 20px 0;
  border-radius: 8px;
}

/* .section {
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
} */

.download-section {
  margin-top: 40px;
}

.app-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.app-badge {
  width: 150px;
}
