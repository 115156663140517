.reset-password-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
  display: block;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reset-password-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reset-password-input-group {
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

.reset-password-input-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.reset-password-input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.reset-password-button {
  background-color: var(--button-blue);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
}

.reset-password-button:hover {
  background-color: #0056b3;
}

.reset-password-message {
  margin-top: 20px;
  color: #333;
}
