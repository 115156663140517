.search-input {
  width: 100%;
  max-width: 100%; /* Garante que não ultrapasse o limite do contêiner */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
   box-sizing: border-box; /* Inclui padding e borda dentro da largura */
}



input {
	font-size: 18px;	
}


.sport-center-list {
  margin-top: 10px;
}

.sport-center-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  min-height: var(--clickable-app-height) !important;	
  min-width: var(--clickable-app-height) !important;
}

.sport-center-item:hover {
  background-color: var(--color-light);
}

.select-sport-center-group {
  display: inline-block;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box; /* Garante que o padding e borda estejam dentro da largura definida */  
  max-width:550px;
}

