.register-container {
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
  display: block;
  max-width: 550px;
  width: 100%;
  margin: 0 auto; /* Centraliza horizontalmente */
  height: calc(100vh - var(--footer-height) - var(--header-height)  - var(--header-padding) ); /* Ajusta a altura mínima para ocupar toda a tela */
  #display: flex;
  #flex-direction: column;
  #justify-content: center;
  overflow-y: auto;
  box-sizing: border-box;
}



.register-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.register-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-input-group {
  margin-bottom: 10px;
  text-align: left;
  width: inherit;
}

.register-input-group label {
  letter-spacing: -.006em;
  font-size: var(--font-size-14);
  line-height: 155%;
  font-weight: var(--font-weight-regular);  
  top: -3px;
  position: relative;
}

.register-input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.register-button {
  background-color: var(--button-blue);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  width: inherit;
  
  font-size: 16px;  
  line-height: 24px;
}

.register-button:hover {
  background-color: #0056b3;
}

.register-footer {
  #margin-top: 20px;
  font-size: 14px;
}

.register-footer a {
  color: var(--button-blue);
}

.register-footer a:hover {
  text-decoration: underline;
}
