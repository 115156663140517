/* .red-dot { */
  /* height: 10px; */
  /* width: 10px; */
  /* background-color: red; */
  /* border-radius: 50%; */
  /* display: inline-block; */
  /* margin-top: 0px; */
/* } */
.court-details-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  #padding: 20px;
  background-color: #f7f7f7;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.court-details-page h3 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--color-dark);
}

#court-date {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

#court-date:hover, #court-date:focus {
  border-color: var(--color-accent);
  background-color: var(--color-light);
  outline: none;
}

/*Calendar*/

.react-calendar {
  #border: none; /* Remover bordas padrão */
  border-radius: 10px; /* Bordas arredondadas no calendário */
  overflow: hidden; /* Garante que o conteúdo não ultrapasse as bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona sombra para destacar */
}

.react-calendar__tile {
  #border-radius: 8px; /* Bordas arredondadas nos dias */
  padding: 10px;
  transition: background-color 0.3s ease;
  
  

}

.react-calendar__tile--active {
  background-color: var(--color-accent); /* Cor do dia selecionado */
  color: white;
}

.react-calendar__tile:hover {
  background-color: var(--color-light); /* Cor de hover */
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #ccc; /* Datas do mês anterior ou seguinte mais claras */
}

.react-calendar__tile--now {
  background: #fff3cd; /* Fundo diferente para o dia atual */
  #border-radius: 8px;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.react-calendar__navigation button {
  #color: var(--color-accent);
  background: none;
  font-size: 16px;
  margin-top: 8px;
  cursor: pointer;
}

.react-calendar__navigation button:disabled {
  #display: none; /* Esconde botões desabilitados, como o de ano */
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: bold;
  #font-size: 14px;
  color: var(--color-dark);
}

.react-calendar__month-view__weekdays__weekday {
  #padding: 10px;
}

.red-dot {
  height: 6px; /* Ajuste o tamanho da bolinha */
  width: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: absolute; /* Use position absolute para posicionar melhor */
  bottom: 6px;
  right: 6px;
}

.react-calendar__tile {
  position: relative;
  #height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  #border-radius: 10px;
  cursor: pointer;
  

}




