/* variables.css */
:root {
  --color-primary: #ffd700; /* Dourado */
  --color-secondary: #282c34; /* Preto */
  --color-accent: #3498db; /* Azul para botões */
  --color-accent-hover: #2980b9; /* Azul mais escuro para hover */
  --color-light: #f0f0f0; /* Cinza claro, pode ser usado em backgrounds */
  --color-dark: #333; /* Cinza escuro para texto */
  --footer-height: 100px;
  --header-height: 100px;
  --videos-page-padding: 20px;
  --header-padding: 20px;
  --color-secondary-hover: #3a3f47;
  --progress-height: 55px;
  --controls-height: 70px;
  
  --status-color-long-dead: lightpink;
  --status-color-not-long-alive: khaki;
  --status-color-alive: green;
  --clickable-app-height: 48px;
  --button-blue: #0056b3;
}
