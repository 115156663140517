/* Estilos gerais do container */
.profile-container {
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    box-sizing: border-box;
    display: block;
    max-width: 550px;
    width: 100%;
    margin: 0 auto; /* Centraliza horizontalmente */
    height: calc(100vh - var(--footer-height) - var(--header-height)  - var(--header-padding) ); /* Ajusta a altura mínima para ocupar toda a tela */
    #display: flex;
    #flex-direction: column;
    #justify-content: center;
    overflow-y: auto;
    box-sizing: border-box;
  }
/* Estilos do cabeçalho */
.profile-header h2 {
  font-size: 32px;
  #margin-bottom: 20px;
  #color: var(--color-primary);
}

/* Conteúdo principal do perfil */
.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Estilos do avatar */
.profile-avatar {
  margin-bottom: 20px;
}

.avatar-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid var(--color-primary);
}

/* Estilos dos detalhes do perfil */
.profile-details {
  text-align: center;
  margin-bottom: 30px;
}

.profile-details h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.profile-details p {
  font-size: 16px;
  color: var(--color-dark);
}

/* Estilos dos botões de ações */
.profile-actions {
  text-align: center;
}

.profile-button {
  background-color: var(--button-blue);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  width: inherit;
  
  font-size: 16px;  
  line-height: 24px;
    
}

.profile-button:hover {
  background-color: #0056b3;
}