body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Display', 'Helvetica Neue', 'Segoe UI', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5; /* Aumenta a legibilidade com espaçamento entre linhas */
  letter-spacing: 0.5px; /* Pequeno ajuste de espaçamento entre letras */
  color: #333; /* Cores suaves para texto */
}

h1, h2, h3, h4, h5, h6 {
  font-family: -apple-system, 'SF Pro Display', 'Helvetica Neue', sans-serif;
  font-weight: bold;
}

p {
  margin: 0 0 1.5em;
  font-size: 16px;
  line-height: 1.6; /* Aumenta o espaçamento entre linhas para parágrafos */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 14px;
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}
