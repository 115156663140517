.combined-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--footer-height) - var(--header-height)  - var(--header-padding) ); /* Ajusta a altura mínima para ocupar toda a tela */
}

.tab-nav {
  display: flex;
  justify-content: center;
  #margin-bottom: 20px;
  #border-bottom: 2px solid #ddd;
}

.tab-button {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 10px;
  border-bottom: 2px solid transparent;
}



.tab-button.active {
  border-bottom: 2px solid var(--button-blue);
  font-weight: bold;
}

.tab-button:hover {
  color: var(--button-blue);
}

.tab-content {
  flex: 1; /* This makes the content area expand to fill available space */
  overflow-y: auto; /* Add scrolling if content overflows */
  #padding: 20px;
  border-top: 2px solid #ddd;
  box-sizing: border-box; /* Ensures padding is included in the height */
  background-color: var(--color-light);
  padding: 20px;
}
