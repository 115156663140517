.hours-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.hours-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.hour-box {
  padding: 10px 20px;
  background-color: var(--color-accent);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  text-align: center;
}

.hour-box:hover {
  background-color: var(--color-accent-hover);
}

.hour-box.selected {
  background-color: #2ecc71;
}

.hours-grid p {
  font-size: 16px;
  color: #999;
}
