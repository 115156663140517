.status-page {
  padding: var(--videos-page-padding);
  background-color: var(--color-light);
  min-height: 100vh;
}

.equipment-list {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 10px 0;
}

.equipment-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 300px;
  flex-shrink: 0;
  border: 1px solid var(--color-secondary);
}

.equipment-card h2 {
  color: var(--color-accent);
  margin-bottom: 10px;
}

.equipment-card p {
  margin: 5px 0;
  color: var(--color-dark);
}

.equipment-card ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
}

.equipment-card ul li {
  background-color: var(--color-light);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--color-secondary);
}

.equipment-card ul li:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .equipment-card {
    min-width: 250px;
  }
}

@media (max-width: 480px) {
  .equipment-card {
    min-width: 200px;
  }
}
