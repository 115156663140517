.social-wrapper {
  margin: 10px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.social-wrapper-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  #margin-top: 25px;
  #margin-bottom: 15px;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--neutral-90);
}

.social-wrapper-title span {
  padding: 0 10px;
  color: #666;
  text-transform: uppercase;
}

.social-wrapper-title::before,
.social-wrapper-title::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
  margin: 0 10px;
}
/*
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  min-height: 100vh;
  width: 100vh;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden; 
}
*/
.login-header {
  margin-bottom: 20px;
}

.login-logo {
  display: block;
  margin: 0 auto;
  width: 150px;
  height: auto;
}

.login-container {
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
  display: block;
  max-width: 550px;
  width: 100%;
  margin: 0 auto; /* Centraliza horizontalmente */
  height: calc(100vh - var(--footer-height) - var(--header-height)  - var(--header-padding) ); /* Ajusta a altura mínima para ocupar toda a tela */
  #display: flex;
  #flex-direction: column;
  #justify-content: center;
  overflow-y: auto;
  box-sizing: border-box;
}


.login-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form a {
  color: var(--button-blue);
  padding-top: 20px;
}

.login-input-group {
  margin-bottom: 15px;
  text-align: left;
  width: inherit;
}

.login-input-group label {
	letter-spacing: -.006em;
	font-size: var(--font-size-14);
	line-height: 155%;
	font-weight: var(--font-weight-regular);  
	top: -3px;
	position: relative;
}

.login-input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;  
}


.login-button {
  background-color: var(--button-blue);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  width: inherit;
  
  font-size: 16px;  
  line-height: 24px;
    
}

.login-button:hover {
  background-color: #0056b3;
}

.social-login {
  margin: 20px 0;
}

.social-button {
  width: 100%;
}

.login-footer {
  margin-top: 20px;
  font-size: 14px;
}

.login-footer a {
  color: var(--button-blue);
}

.login-footer a:hover {
  text-decoration: underline;
}
