
.sport-centers-master-div {
  width: 100%;
  text-align: center;
}

.sport-centers-master-div h2 {
  text-align: center;
}

.left-panel {
  display: none; /* A barra da esquerda foi removida */
}

.main-content {
  width: 100%; /* Ocupa toda a largura disponível */
  padding: 0; /* Remove o padding */
  overflow-y: auto;
}

.vertical-separator {
  display: none; /* Removido para abordagem mobile */
}

.sport-centers-scrollable {
  display: flex;
  overflow-x: auto; /* Habilita a rolagem horizontal */
  padding-bottom: 10px; /* Espaço para garantir que o conteúdo não toque na borda */
}

.sport-centers-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha */
  justify-content: flex-start;
}

.sport-center-container {
  width: 100%; /* Largura ajustada para ocupar toda a tela no mobile */
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.sport-center-box {
  position: relative;
  width: 100%; /* Ajusta a largura dos elementos para ocupar toda a tela no mobile */
  max-width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;    
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 2px solid #ccc;
  overflow: hidden;
  cursor: pointer;
}

.add-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  color: white;
  padding: 10px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-button {
  margin-top: 10px;
  background-color: #e74c3c;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-button2 {
  margin-top: 10px;
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-button:hover,
.remove-button:hover,
.add-button2:hover {
  background-color: #c0392b;
}

.selected-sport-center,
.selected-court,
.selected-date-hour {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.selected-sport-center-box,
.selected-court-box,
.selected-date-box,
.selected-hour-box {
  background-color: #f1f1f1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.change-button {
  background-color: var(--color-accent);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.change-button:hover {
  background-color: var(--color-accent-hover);
}

.section-header {
  display: flex;
  align-items: center;  /* Alinha o ícone e o texto verticalmente no meio */
  justify-content: center;  /* Centraliza o conteúdo horizontalmente */
  font-size: 18px;
  padding: 0px;
  background-color: #ccc;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ccc;
  height: 30px;  /* Defina uma altura que faça sentido para o layout */
  padding-left: 10px;  /* Adiciona espaçamento à esquerda do conteúdo */
}

.section-header svg {
  margin-right: 10px;  /* Espaçamento entre o ícone e o texto */
}


.cameras-container {
  #margin-bottom: 20px;
  #padding: 0px;
  border-radius: 8px;
}

.camera-columns {
  display: flex;
  overflow-x: auto; /* Habilita a rolagem horizontal */
  #gap: 1px; /* Espaçamento entre os vídeos */
}

.camera-column {
  flex: 0 0 calc(50% - 1px); /* Cada vídeo ocupa 50% da largura do contêiner, menos o espaço do gap */
  max-width: calc(50% - 1px); /* Garante que o vídeo não ultrapasse essa largura */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.thumbnail {
  width: 100%;  /* O thumbnail ocupa 100% da coluna */
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #ccc;
  aspect-ratio: 16 / 9;  /* Define a proporção de aspecto 16:9 */
  object-fit: cover;  /* Mantém o conteúdo dentro dos limites, cortando se necessário */
  background-color: black;
  box-sizing: border-box;
}


.download-button {
  display: block;
  margin-top: 10px;
  text-align: center;
  background-color: var(--color-accent);
  color: white;
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
}

.download-button:hover {
  background-color: var(--color-accent-hover);
}

.fixed-video-player {
  height: auto; /* Ajuste o tamanho automaticamente */
  max-height: 100vh; /* Limita a altura máxima a 80% da altura da viewport */
  width: 100%;
  max-width: 1000px; /* Limita a largura máxima a 1000px TEM Q ALTERAR PRA SER DE ACORDO COM O MAXIMO Q DA PRA VER VIDEO  + Controles*/ 
  background-color: black;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  margin: 0 auto; /* Centraliza o player */
}

.fixed-video-player video {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.sportcenternamediv {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin: 0;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}


h1 {
  text-align: center;
  color: var(--color-dark);
  margin-bottom: 20px;
}

h2 {
  text-align: center;
  color: var(--color-dark);
  margin-bottom: 20px;
}
h3 {
  text-align: center;
  color: var(--color-dark);
  margin-bottom: 20px;
}
h4 {
  text-align: center;
  color: var(--color-dark);
  margin-bottom: 20px;
}


.toggle-filters-button {
  background-color: var(--color-accent);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.toggle-filters-button:hover {
  background-color: var(--color-accent-hover);
}

  
.filters-container {
  position: relative;
  padding-top: 0px; /* Make space for the fixed back button */
}


.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  color: black;
  
}

.back-icon {	
  width: 40px; 
  height: auto;
  display: block;
}

.update-button {
  position: absolute;  
  top:10px;
  right: 20px;  /* Align to the right */
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  color: black;
}

.update-icon {   
  width: 30px; 
  height: auto;
}

.video-popup {
  position: absolute;
  top:  0;
  #left: 0;
  width: 100vw;  
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);  
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999999;
  flex-direction: column;  
  #padding: var(--header-height) var(--footer-height) 0 0;
}

.refresh-indicator {
  position: absolute;
  top: calc(var(--header-height) + 15px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  transition: transform 0.2s ease;
  #z-index: 99999999;
}

.refresh-indicator.rotating {
  animation: rotating 1s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.refresh-logo {
  width: 50px;  /* Ajuste o tamanho conforme necessário */
  height: 50px;
}

.videos-page {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  transition: transform 0.5s ease-out;
  #background-color:red;
  height: calc(100vh - var(--header-height) - var(--footer-height) - var(--header-padding) );
  box-sizing: border-box;
  
}
.videos-page.no-animation {
  transition: none;
}
.video-popup-max {
  position: absolute;
  top:  0;
  left: 0;
  width: 100vw;  
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);  
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999999;
  flex-direction: column;  
  #padding: var(--header-height) var(--footer-height) 0 0;
}



.video-container-max {
  position: relative;
  padding-top: -40px!important;
  border-radius: 10px;           
  width: calc((100vh  - 2px - var(--progress-height) - var(--controls-height)) * 16 / 9); 
  max-width: 100vw;    
  margin: 0 auto;  
  background-color: black;    
  
  border: solid 1px white;
  box-sizing: border-box;

}


.newDivInsteadOfSwiper
{
	display: flex!important;
    gap: 5px;
}

.DivInsteadOfSwiperSlide
{
	width: calc(100vw/2);
}






