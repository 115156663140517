.video-container {
  position: relative;
  padding: 0px;
  border-radius: 10px;        
    
  width: calc((100vh - var(--header-height) - var(--header-padding) - 1px - var(--footer-height) - var(--progress-height) - var(--controls-height)) * 16 / 9); /* Largura proporcional à altura fixa */  
  max-width: 99vw;    
  margin: 0 auto;  
  background-color: black;    
  
  border: solid 1px white;
  box-sizing: border-box;

}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  border-radius: 10px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.progress-bar {
  width: 80%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  margin-top: 20px;
}

.progress {
  height: 100%;
  background-color: white;
  border-radius: 4px;
}
.video-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  #gap: 10px; /* Space between buttons */  
  height:var(--controls-height);
  
}
.controls-left {
  display: flex;
  #gap: 10px; /* Espaçamento entre os botões */
}
.controls-right {
  color: #fff; /* Estilização do texto de tempo */
  padding-right: 15px;
}

.video-controls button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
  transition: transform 0.2s ease, color 0.2s ease;  
}

.video-controls button:hover {
  transform: scale(1.2); /* Scale the button on hover */
}

.video-controls button:disabled:hover {
  transform: none; /* Scale the button on hover */
}


.filters-container button:hover svg {
  color: #ffcc00; /* Change icon color on hover (optional) */
}


.video-container  button:hover svg {
  color: #ffcc00; /* Change icon color on hover (optional) */
}

.video-controls button svg {
  font-size: 24px;
  color: white; /* Default color for icons */
  transition: transform 0.2s ease, color 0.2s ease;  
}


.video-info {
  font-size: 14px;
  color: var(--color-dark);  
  height: var(--progress-height);  
  align-content: center;  
}

.video-info-native {
  padding: 0px 20px 0px 20px; 
}

.video-info-nonnative {
  padding: 0px 30px 0px 30px; 
}

.video-controls span {
  color: #fff;
}

.downloading-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  z-index: 1000;
}



.selected-video {
  border: 4px solid var(--color-accent-hover); /* Ajuste a cor conforme necessário */
  box-sizing: border-box; /* Garante que a borda não afete o tamanho do elemento */
}

.close-button-p {
  position: absolute;  
  background: transparent;
  
  #background-color: red;
  border: none;
  
  color: white;
  cursor: pointer;
  z-index: 9999;
  right: 10px;
  top: 10px;
}


/* Dica de rotação com fadeOut automático */
.rotate-hint {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 99999;
  font-size: 14px;
  text-align: center;
  animation: fadeOut 5s forwards; /* Controla o desaparecimento da dica via CSS */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

button:disabled svg {
  color: #ccc; /* Alterar a cor do ícone SVG */
  cursor: not-allowed; /* Cursor indicando que está desabilitado */
  opacity: 0.5; /* Deixar o ícone semi-transparente, opcional */
}
