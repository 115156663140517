.court-button {
  background-color: var(--color-accent);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  text-align: center;
  display: block;
  margin: 10px auto;
}

.court-button:hover {
  background-color: var(--color-accent-hover);
}
