.footer {
  display: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-secondary);
  color: white;
  height: var(--footer-height);
}

.footer-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.footer-logo img {
  height: 50px;
}
.footer-copyright {
  text-align: center;
  flex: 1;
}


.footer-social {
  display: flex;
  gap: 15px;
}
/*
  Estilos ios
*/

.tab-bar {
  display: flex;
  justify-content: space-around;
  background-color: var(--color-secondary);
  color: white;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  height: var(--footer-height); 
}

.tab-bar-item {
  text-align: center;
  text-decoration: none;
  color: white;
   min-height: var(--clickable-app-height) !important;	
   min-width: var(--clickable-app-height) !important;
}

.tab-bar-item span {
  display: block;
  font-size: 12px;
}

.tab-bar-item svg {
  display: block;
  margin: 0 auto;
}

/*submenu*/

.submenu { 
  position: fixed;
  bottom: calc(var(--footer-height) + var(--header-padding));
  right: 0px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  padding: 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 200px;
  
}

.submenu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  text-decoration: none;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex-direction: column-reverse;
}

.submenu-item:hover {
  background-color: #f0f0f0;
  color: var(--button-blue);
  border-radius: 4px;
}

/* fim submenu*/
/* Fim Ios*/

.footer-social a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #61dafb;
}

