@import url('variables.css');

.input {
	font-size: 16px;
	background-color: blue  !important;
	min-height: var(--clickable-app-height) !important;
}


input {	
   min-height: var(--clickable-app-height) !important;	
   min-width: var(--clickable-app-height) !important;	
}

button {	
	min-height: var(--clickable-app-height) !important;	
	min-width: var(--clickable-app-height) !important;	
}

a {	
	min-height: var(--clickable-app-height) !important;	
	min-width: var(--clickable-app-height) !important;	
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height);
  margin: 0;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--color-secondary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body, #root {
  height: 100%;
  margin: 0;
  overflow: auto; /* Mantém o scroll global */
}

html {
  scroll-behavior: smooth;
}
/* 
.section {
  padding: 100px 20px;
} */


/* Admin Pages Styling */
.admin-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.admin-page h1, .admin-page h2 {
  margin-bottom: 20px;
  color: var(--color-dark);
  text-align: left;
}

.admin-page .form-container {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.admin-page .form-container input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.admin-page button {
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: var(--button-blue);
  color: white;
  cursor: pointer;
  margin: 5px;
}

.admin-page button:hover {
  background-color: #0056b3;
}

.admin-page .item-card {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-page .item-card img {
  max-width: 100px;
  border-radius: 5px;
  margin-right: 20px;
}

.admin-page .item-card h3 {
  margin: 0;
  flex-grow: 1;
  text-align: left;
}

.admin-page .item-card button {
  margin-left: 10px;
}

/* Media Queries for Responsive Design */
@media only screen and (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin);
    padding: 20px;
  }

  .App-logo {
    height: 30vmin;
  }
/* 
  .section {
    padding: 50px 10px;
  } */

  .admin-page {
    padding: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .App-header {
    font-size: calc(6px + 2vmin);
    padding: 10px;
  }

  .App-logo {
    height: 20vmin;
  }
/* 
  .section {
    padding: 30px 5px;
  } */

  .admin-page {
    padding: 5px;
  }

  .admin-page .form-container input {
    width: calc(100% - 10px);
  }
}


.error-message {
  color: red;
  white-space: pre-wrap; /* Preserva quebras de linha e espaços */  
  margin-top: 10px;
}

