.selected-item {
  position: relative;
  width: 100%;
  height: 60px;
  #background-color: lightblue;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.selected-item-box {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #ccc;
  overflow: hidden;
  text-align: center;
  color: black;
  font-weight: bold;
  background-color: gainsboro;
  
}


